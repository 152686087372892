<template>
  <div class="vipMain">
    <div class="gh-300p gpv-40 gph-40 gb-aaa g-flex g-justify-end g-align-center">
      <div class="gpv-20 gph-20 gb-fff gbr-10 gw-300p">
        <div class="g-flex gmb-20">
          <img src="https://resource.ivyexam.com/21ec64bb-1b0c-426c-8adf-8ec7fc155243.png" class="gh-60p gw-60p gmr-10" style="border-radius:50%">
          <div class="g-flex g-flex-column g-justify-space-between gpv-3">
            <div class="gfw-b gfs-18">{{userInfo.name}}</div>
            <div>{{userInfo.is_vip?`${userInfo.vip_time}到期`:'暂未开通会员'}}</div>
          </div>
        </div>
        <a-button type="primary" block @click="showVip">
          {{userInfo.is_vip?'立即续费':'立即开通'}}
        </a-button>
      </div>
    </div>
    <a-row class="gmt-20" :gutter="20">
      <a-col :span="8" v-if="hasTranslateRole">
        <div class="gh-150p gbd gbr-10 gpv-20 gph-20">
          <div class="gfw-b gfs-18 gmb-20">原文翻译</div>
          <div class="gc-aaa">机经的原文内容的中文翻译展示</div>
        </div>
      </a-col>
      <a-col :span="8" v-if="hasListenRole">
        <div class="gh-150p gbd gbr-10 gpv-20 gph-20">
          <div class="gfw-b gfs-18 gmb-20">音频畅听</div>
          <div class="gc-aaa">机经的音频无限畅听</div>
        </div>
      </a-col>
      <a-col :span="8" v-if="hasWrodRole">
        <div class="gh-150p gbd gbr-10 gpv-20 gph-20">
          <div class="gfw-b gfs-18 gmb-20">单词真题</div>
          <div class="gc-aaa">单词词汇的真题例句查看</div>
        </div>
      </a-col>
    </a-row>
    <!-- vip会员 -->
    <a-modal title="VIP会员" :visible="vipDialog" :confirm-loading="confirmLoading" :footer="false" @cancel="vipDialog=false" width="900px">
      <div>
        <div class="g-flex gpb-20 " style="border-bottom:1px solid #aaa;">
          <img src="https://resource.ivyexam.com/21ec64bb-1b0c-426c-8adf-8ec7fc155243.png" class="gh-60p gw-60p gmr-10" style="border-radius:50%">
          <div class="g-flex g-flex-column g-justify-space-between gpv-3">
            <div>
              <span class="gfw-b gfs-18 gmr-10">{{userInfo.name}}</span>
              <span>(登录账号：188****5487)</span>
            </div>
            <div>{{userInfo.is_vip?`${userInfo.vip_time}到期`:'暂未开通会员'}}</div>
          </div>
        </div>
        <div class="g-flex g-wrap gmt-20 gmb-20">
          <div class="gw-20 gmr-40 vipItem gpv-20 gtc g-pointer" :class="{active:curVipIndex==index}" v-for="(item,index) in vipList" :key="index" @click="chooseCurVip(index)">
            <div class="gmb-10">{{item.effect_day}}个月</div>
            <div class="gmb-10 gfs-18 gfw-b">¥{{item.is_discount?item.discount_price:item.price}}</div>
            <div class="gtd-lh" v-if="item.is_discount">¥ {{item.price}}</div>
          </div>
        </div>
        <div style="border:1px solid #aaa;" class="g-flex gh-200p" v-if="vipList.length>0">
          <div class="gw-200p gpv-10 gph-10" style="border-right:1px solid #aaa">
            <div class="gpv-15 payItem gbr-4 g-pointer gtc" :class="{active:payIndex==0}" @click="choosePay(0)">微信支付</div>
            <div class="gpv-15 payItem gbr-4 g-pointer gtc" :class="{active:payIndex==1}" @click="choosePay(1)">支付宝支付</div>
          </div>
          <div class="g-flex-1 gpv-20 gph-40 g-flex g-align-center">
            <img v-if="payIndex==0" :src="`/word/api/v1/student/get-wechat-qrcode/${orderId}`" class="gh-150p gw-150p">
            <div>
              <div class="gfw-b gfs-22 gmb-20">￥{{vipList[curVipIndex].is_discount?vipList[curVipIndex].discount_price:vipList[curVipIndex].price}}</div>
              <div class="gc-aaa g-pointer vipService" @click="toAgreement">《会员服务协议》</div>
              <a-button class="gmt-20" v-if="payIndex==1" type="primary" @click="toZhiFuBaoPay">立即支付</a-button>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <div ref="AlipayFormBox" v-html="alipayHtml"></div>
  </div>
</template>
<script>
import { getVipList, createOrder, getAlipayQrcode } from "@/api/personal";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      vipDialog: false,
      curVipIndex: 0,
      vipList: [],
      payIndex: 0,
      confirmLoading: false,
      orderId: "",
      alipayHtml: "",
      wxCode: "",
    };
  },
  computed: {
    ...mapGetters(["hasWrodRole", "hasTranslateRole", "hasListenRole"]),
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  created() {
    this.getVipList();
  },
  methods: {
    //协议跳转
    toAgreement() {
      let newUrl = this.$router.resolve({
        path: "/agreement",
      });
      window.open(newUrl.href, "_blank");
    },
    //支付宝支付
    toZhiFuBaoPay() {
      getAlipayQrcode(this.orderId).then((res) => {
        this.alipayHtml = res;
        this.$nextTick(() => {
          let form = this.$refs.AlipayFormBox.getElementsByTagName("form")[0];
          form.submit();
        });
      });
    },
    //创建支付订单
    createOrder() {
      createOrder(this.vipList[this.curVipIndex].id).then((res) => {
        this.orderId = res.data;
      });
    },
    //会员列表
    getVipList() {
      getVipList({ campus_id: this.userInfo.campus_id }).then((res) => {
        if (res.code == 200) {
          this.vipList = res.data;
          if (this.vipList.length > 0) {
            this.createOrder();
          }
        }
      });
    },
    //开通vip会员
    openVipClick() {},
    //vip弹框
    showVip() {
      if (this.vipList.length > 0) {
        this.createOrder();
      }
      this.vipDialog = true;
    },
    //选择vip
    chooseCurVip(index) {
      this.curVipIndex = index;
      this.createOrder();
    },
    //选择支付方式
    choosePay(index) {
      this.payIndex = index;
    },
  },
};
</script>
<style lang="less" scoped>
.vipItem {
  border: 1px solid #aaa;
  color: #1890ff;
  &.active {
    background: #1890ff;
    color: #fff;
  }
}
.payItem {
  &.active {
    background: #1890ff;
    color: #fff;
  }
}
.vipService:hover {
  color: #1890ff;
}
</style>